<template>
  <div>
    <!-- pic4E42EA1FE08BB27D092E8D5A293125DB.jpg -->
    <img class="img-large rounded" src="">
    <p>
      <a href="https://www.saferinternet.org/web/guest/home">Insafe</a> is a respected international organization that promotes safer and more responsible use of online technology and mobile phones, especially among young people! Insafe organizes Safer Internet Day, which is celebrated this year on February 9th.
    </p>
    <p>
      Now you can join in the 2010 Safer Internet Day festivities by overcoming a special challenge in the <router-link :to="{ name: 'creation-lab-list', params: {challengeId: '49e094aa-3bed-4a66-be9d-ca20e3ea28eb'} }">LEGO Universe Creation Lab</router-link>. Show everyone what you think moderators should look like in the gameworld!
    </p>
  </div>
</template>
